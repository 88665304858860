*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body{
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input[type="text"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
     border: none;
     outline: none;
     display: block;
     width: 100%;
     border-radius: 0;
}


.width{
    max-width: 600px;
    margin: 0 auto;
    width: 92%; 
}

.header{
    background:#2D6BCC;
    padding:  15px 0;



}
.h1{
  text-align: center;
    color: white;
    text-transform:  uppercase;
    font-size: 20px;
    letter-spacing: 2px;


}

a{
    text-decoration: none;

}
 img{
    max-width: 100%;
    display: block;
 }

h1 a {
     color: white;
     
}
 .logo{
    text-align: center;
 }

 .home-search{
    padding: 40px 0 ;
    text-align:  center;
    h2{
        font-size: 25px;
      margin-bottom: 20px;
    }

     input{ 
        border:1px solid
         #ddd;
         border-radius: 8px;
        padding: 0 24px;
        line-height: 54px;
     }
 }
 .homecrypto{
    padding-bottom:30px ; 
    
       h2{
        font-size: 25px;
      margin-bottom: 20px;
      text-align: center;
 margin-bottom: 20px;
    }
 }

 .homecrypto{
    a{
        display: flex;
        gap: 30px;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #ddd;
    }
    
    span{
        display: block;
    }

 }

  .homecryptoimage{
    width: 50px;
  }

 .homecryptoname{
    flex: 1;
    font-size: 20px;
    font-weight: 400;
    color: #111;
 }
 .homecryptobtc{
color: #111;
font-size: 18px;
img{
    width: 20px;
    display: inline-block;
    margin: -3px 5px 0 0;
    vertical-align: middle;

}
 }
 .homecryptousd{
    color: #666;
    font-size: 10 px;

 }

 .showheader{
    padding: 40px;
    text-align: center;
    img{
        width: 80px;
        border-radius: 50%;
        margin: 0 auto;
    }
    h2{
        font-size: 32px;
margin-top: 10px;
    }
 }

 .showgraph{
    height: 200px;
    width: 100%;
    
 }

 .showdetails{

    padding-top:40px ;
    padding-bottom: 60px;
     h2{
        font-size: 25px;
      margin-bottom: 20px;
      text-align: center;
 margin-bottom: 20px;
     }

}

.showdetailsrow{
    display: flex;
    align-items:center ;
    justify-content: space-between;
    padding: 20px 0 ;
    color: #666;
    border-bottom: 1px solid #ddd;
 h3{
   font-size: 16px;
color: #111;
 }
}